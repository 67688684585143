@import "variables";
@import "framework/framework";
@import "framework/feed";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

html {
	background: $black;
	font-family:"lulo-one", sans-serif;
	font-weight: 700;
	font-size: 1rem;
	line-height: 1;
	scroll-behavior: smooth;

	@include desktop-sm-down {
		font-size: 0.9375rem;
	}

	@include tablet-down {
		font-size: 0.875rem;
	}

	@include phone-down {
		font-size: 0.8125rem;
	}

	@include phone-sm-down {
		font-size: 0.75rem;
	}
}

body {
	color: $white;
}

a {
	color: $white;

	@include hover-focus {
		opacity: .7;
	}
}

h1,h2,h3,h4,h5,ul,ol, p{
	margin: 0;
}

p{
	font-size: 1rem;
	line-height: 1.4;
}

.hide{
	display: none;
}

.mobile{
	@include tablet-up{
		display: none !important;
	}
}

.desktop{
	@include tablet-down{
		display: none !important;
	}
}

.socials{
	@include flex(row,center,center);
	gap: 1.25rem;
	a{
		font-size: 1.25rem;
	}
}

.split{
	@include flex(column,initial,initial);
	.side{
		flex: 1;
	}
	@include tablet-up{
		@include flex(row,initial,initial);
	}
}

.sr {
	border: 0;
	height: 0.0625rem;
	left: -6249.9375rem;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.0625rem;
}

.menu-trigger{
	order: 2;
	display: block;
	border: none;
	background-color: transparent;
	border: none;
	border-style: none;
	border: solid 0.125rem transparent;
	border-top-color: $white;
	border-bottom-color: $white;
	position: relative;
	width: 1.875rem;
	height: 1.125rem;
	transition: all .4s;
	&:before,&::after{
		content: '';
		display: block;
		width: 100%;
		height: 0.125rem;
		top: calc(50% - 0.0625rem);
		left: 0;
		position: absolute;
		background-color: $white;
		transition: all .4s;
	}
	&.active{
		border-color: transparent;
		&::after{
			transform: rotate(45deg);
		}
		&::before{
			transform: rotate(-45deg);
		}
	}
}

.has-bg{
	position: relative;
	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: top left;
	}
	.content{
		position: relative;
	}
}

.heading{
	font-size: 2.375rem;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 3.75rem;
}

.social{
	list-style: none;
	padding: 0;
	@include flex(row,center,center);
	gap: 1.25rem;
	font-size: 1.25rem;
}
.btn{
	display: inline-block;
	text-transform: uppercase;
	padding: 1.25rem;
	padding: 0.75rem 1.25rem;
	&.btn-white{
		background-color: $white;
		color: $black;
		@include hover-focus{
			color: $green-black;
		}
	}
	&.btn-big{
		@include tablet-up{
			font-size: 1.625rem;
			padding: 1.375rem 6.25rem;
		}
	}
}

.slick-arrow{
	position: absolute;
	top: calc(50% - 0.9375rem);
	font-size: 1.875rem;
	background-color: transparent;
	color: $white;
	border: none;
	padding: 0;
	cursor: pointer;
	z-index: 99;
	&.slick-prev{
		left: 0;
	}
	&.slick-next{
		right: 0;
	}
	i{
		font-weight: 400;
	}
}

header {
	padding: 1.25rem 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	h1{
		font-size: 3.75rem;
		margin: 0;
		@media screen and (max-width: 1460px){
			font-size: 3rem;
		}
		@media screen and (max-width: 1360px){
			font-size: 2.5rem;
		}
		@media screen and (max-width: 1260px){
			font-size: 2rem;
		}
	}
	.container{
		position: relative;
		.split{
			@include flex(column,center,center);
			.side{
				width: 100%;
				.logo{
					width: 31.25rem;
					width: 12.5rem;
				}
				.menu{
					list-style: none;
					padding: 0;
					background-color: $green-black;
					position: fixed;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					z-index: 999;
					opacity: 0;
					pointer-events: none;
					transition: all .4s;
					@include flex(column,center,center);
					.social{
						padding: 0.625rem 0 1.875rem;
					}
					>li{
						a{
							text-transform: uppercase;
							font-size: 1rem;
							text-align: center;
							display: block;
							padding: 1.25rem;
						}
					}
					&.active{
						opacity: 1;
						pointer-events: all;
					}
				}
				.menu-trigger{
					position: absolute;
					top: 0;
					right: 0.9375rem;
					z-index: 9999;
				}
				&:last-child{
					text-align: center;
				}
			}
		}
	}

	@include tablet-up{
		padding: 3.125rem 2.5rem 0;
		.container{
			max-width: 100%;
			>.split{
				@include flex(row,space-between,flex-start);
				>.side{
					flex: 1;
					max-width: max-content;
					.logo{
						width: 31.25rem;
					}
					.menu{
						opacity: 1;
						pointer-events: all;
						position: unset;
						background-color: transparent;
						@include flex(row,flex-end,initial);
						gap: 1.25rem;
						li{
							a{
								padding: 0;
							}
						}
					}
					.btn{
						display: inline-block;
					}
					.social{
						padding: 0;
						@include flex(row,flex-end,center);
					}
					.split{
						@include flex(row,flex-end,center);
						gap: 1.875rem;
						.side{
							flex: 1;
							max-width: max-content;
						}
					}
				}
			}
		}
	}
}

main {
	#hero{
		position: relative;
		.bg{
			object-fit: cover;
			object-position: center left;
			width: 100%;
			max-height: 60vh;
			@include tablet-up{
				max-height: 100vh;
			}

		}
	}
	.top-bg{
		background-color: #1D1D1B;
		.bg{
			opacity: .4;
		}
	}

	#music{
		padding: 10rem 0 0;
		.container{
			.wrapper{
				padding:  0 1.875rem;
				.music-item{
					.btn{
						font-size: 1rem;
						padding: 1em;
					}
					.split{
						gap: 1.25rem;
						align-items: center;
						.side{
							text-align: center;
							img{
								width: 100%;
								max-width: 36rem;
							}
							p{
								font-size: 2.25rem;
								span{
									display: inline-block;
									font-size: 1rem;
								}
							}
							span{
								font-size: 1rem;
								text-transform: uppercase;
								margin: 1.25rem 0;
								display: block;
							}
							&:first-child{
								a{
									display: block;
								}
							}
						}
					}
				}
			}
		}
	}

	#videos{
		padding: 3.75rem 0;
		.container{
			text-align: center;
			.slider-videos{
				padding:  0 2.5rem;
				margin-bottom: 3.75rem;
			} 
		}
	}

	#tour{
		position: relative;
		.content{
			padding: 3.75rem 0 6rem;
			.container{
				text-align: center;
			}
		}

		.tour-wrap{
			border: 0.0625rem solid #fff8;
			padding: 1.5rem;
			overflow-y: scroll;
			max-height: 50rem;
			scrollbar-color: #FFF8 transparent;
			scrollbar-width: thin;
		}
	}

	#about{
		.bg{
			object-fit: contain;
			object-position: bottom left;
		}
		.content{
			padding: 3.75rem 0;
			z-index: 99;
			.container{
				text-align: left;
				.wrapper{
					width: 60%;
					margin-left: auto;
					
					@include tablet-down{
						width: 100%;
						margin-bottom: 60vw;
					}
				}
				.heading{
						text-align: center
				}
				.bio{
					@include flex(column,initial,initial);
					gap: 1.875rem;
					p{
						font-size: 1rem;
						line-height: 1.4;
					}
				}
			}
		}
		
	}

	#shop{
		.bg{
			opacity: .5;
			object-position: center;
			@include tablet-down{
				object-position: 70% 50%;
			}
		}
		.content{
			padding: 5rem 0;
		}
		.container{
			text-align: center;
			max-width: 90rem;
			.wrapper{
				@include flex(row,center,center);
				gap: 2.5rem;
				margin: 8rem 0;
				@include tablet-down{
					flex-direction: column;
					margin: 3rem 0;
					gap: 1rem;
				}
				a{
					display: block;
					flex: 1;
					span{
						display: block;
						margin: 3rem auto;
						@include tablet-down{
							margin: 1.5rem;
						}
					}
					img{
						display: block;
						margin: 0 auto 0.625rem;
						@media screen and (max-width: 1000px){
							max-width: 15rem;
						}
					}
				}
			}
		}
	}

	.bottom{
		background: url('../img/bg-bottom.jpg');
		background-size: cover;
		background-position: bottom center;

		#partnerships{
			padding: 3.75rem 0;
			.container{
				text-align: center;
				p{
					font-size: 1.125rem;
					margin-bottom: 1.875rem;
					display: inline-block;
				}
				.wrapper{
					max-width: 46.25rem;
					margin: 0 auto;
					@include flex(column,center,center);
					gap: 1.875rem;
					a{
						display: block;
						img{
							width: 100%;
							max-height: 12.5rem;
							max-width: max-content;
							object-fit: contain;
							transition: all .4s;
						}
					}
				}
				.split{
					gap: 2.5rem;
					.side{
						@include flex(column,center,center);
						gap: 1.25rem;
						font-size: 1.5rem;
						img{
							height: 12.5rem;
							object-fit: contain;
							transition: all .4s;
							max-width: 25rem;
							width: 100%;
						}
						iframe{
							width: 100%;
							max-width: 37.5rem;
							height: 18.75rem;
						}
					}
				}
			}
			
		}

		#instagram{
			padding: 3.75rem 0;
			@include tablet-up{
				padding: 6.25rem 0 10rem;
			}
		}
	}

}

footer {
	.content{
		padding: 3.75rem 0;
		.container{
			text-align: center;
			max-width: 90rem;
			font-size: 1rem;
			@include flex(column,center,center);
			gap: 1.875rem;
			img{
				display: inline-block;
			}
			.wrapper{
				@include flex(row,center,center);
				gap: 2rem;
				a{
					text-transform: uppercase;
					font-size: 1.5rem;
					display: block;
				}
			}
			.copyright{
				p{
					font-size: 0.75rem;
					text-transform: uppercase;
				}
			}
		}
	}
}

.popup{
	padding: .75rem;
	position: relative;
	width: 100%;
	max-width: 34.375rem;
	margin: 1rem auto;
	text-align: center;
	.mfp-close{
		color: #fff;
	}
	p{
		font-size: 1rem;
		text-transform: uppercase;
		text-align: center;
		margin: 1rem 0;
		&.large{
			font-size: 1.75rem;
		}
	}
}