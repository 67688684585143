@import "variables";
@import "framework/framework";
@import "framework/feed";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

html {
	background: $black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family:"p22-franklin-caslon";
	font-size: 16px;
	line-height: 1;
	scroll-behavior: smooth;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
}

a {
	color: $white;

	@include hover-focus {
		opacity: .7;
	}
}

h1,h2,h3,h4,h5,ul,ol, p{
	margin: 0;
	font-weight: 400;
}

p{
	font-size: 16px;
	line-height: 1.4;
}

.hide{
	display: none;
}

.mobile{
	@include tablet-up{
		display: none !important;
	}
}

.desktop{
	@include tablet-down{
		display: none !important;
	}
}

.socials{
	@include flex(row,center,center);
	gap: 20px;
	a{
		font-size: 20px;
	}
}

.split{
	@include flex(column,initial,initial);
	.side{
		flex: 1;
	}
	@include tablet-up{
		@include flex(row,initial,initial);
	}
}

.sr {
	border: 0;
	height: 1px;
	left: -99999px;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.menu-trigger{
	order: 2;
	display: block;
	border: none;
	background-color: transparent;
	border: none;
	border-style: none;
	border: solid 2px transparent;
	border-top-color: $white;
	border-bottom-color: $white;
	position: relative;
	width: 30px;
	height: 18px;
	transition: all .4s;
	&:before,&::after{
		content: '';
		display: block;
		width: 100%;
		height: 2px;
		top: calc(50% - 1px);
		left: 0;
		position: absolute;
		background-color: $white;
		transition: all .4s;
	}
	&.active{
		border-color: transparent;
		&::after{
			transform: rotate(45deg);
		}
		&::before{
			transform: rotate(-45deg);
		}
	}
}

.has-bg{
	position: relative;
	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: top left;
	}
	.content{
		position: relative;
	}
}

.heading{
	font-size: 38px;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 60px;
}

.social{
	list-style: none;
	padding: 0;
	@include flex(row,center,center);
	gap: 20px;
	font-size: 20px;
}
.btn{
	display: inline-block;
	text-transform: uppercase;
	padding: 20px;
	padding: 12px 50px;
	&.btn-white{
		background-color: $white;
		color: $black;
		@include hover-focus{
			color: $green-black;
		}
	}
	&.btn-big{
		@include tablet-up{
			font-size: 26px;
			padding: 22px 100px;
		}
	}
}

.slick-arrow{
	position: absolute;
	top: calc(50% - 15px);
	font-size: 30px;
	background-color: transparent;
	color: $white;
	border: none;
	padding: 0;
	cursor: pointer;
	z-index: 99;
	&.slick-prev{
		left: 0;
	}
	&.slick-next{
		right: 0;
	}
	i{
		font-weight: 400;
	}
}

header {
	padding: 20px 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	.container{
		position: relative;
		.split{
			@include flex(column,center,center);
			.side{
				width: 100%;
				.logo{
					width: 500px;
					width: 200px;
				}
				.menu{
					list-style: none;
					padding: 0;
					background-color: $green-black;
					position: fixed;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					z-index: 999;
					opacity: 0;
					pointer-events: none;
					transition: all .4s;
					@include flex(column,center,center);
					.social{
						padding: 10px 0 30px;
					}
					>li{
						a{
							text-transform: uppercase;
							font-size: 16px;
							text-align: center;
							display: block;
							padding: 20px;
						}
					}
					&.active{
						opacity: 1;
						pointer-events: all;
					}
				}
				.menu-trigger{
					position: absolute;
					top: 0;
					right: 15px;
					z-index: 9999;
				}
				&:last-child{
					text-align: center;
				}
			}
		}
	}

	@include tablet-up{
		padding: 50px 40px 0;
		.container{
			max-width: 100%;
			>.split{
				@include flex(row,space-between,flex-start);
				>.side{
					flex: 1;
					max-width: max-content;
					.logo{
						width: 500px;
					}
					.menu{
						opacity: 1;
						pointer-events: all;
						position: unset;
						background-color: transparent;
						@include flex(row,flex-end,initial);
						gap: 20px;
						li{
							a{
								padding: 0;
							}
						}
					}
					.btn{
						display: inline-block;
					}
					.social{
						padding: 0;
						@include flex(row,flex-end,center);
					}
					.split{
						@include flex(row,flex-end,center);
						gap: 30px;
						.side{
							flex: 1;
							max-width: max-content;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width:1290px) {
		.container{
			>.split{
				>.side{
					.logo{
						width: 200px;
					}
				}
			}
		}
	}
}

main {

	.hero-music{
		background-color: $green-black;
		.bg{
			max-height: 60vh;
			@include tablet-up{
				max-height: 100vh;
			}
			object-position: left top;
		}
		.content{

			#hero{
				min-height: 60vh;
				@include tablet-up{
					min-height: 100vh;
				}
				position: relative;
				&::after{
					content: "";
					background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(62,57,38,1) 100%);
					width: 100%;
					height: 200px;
					position: absolute;
					bottom: 0;
					left: 0;
				}
			}

			#music{
				padding: 0 0 60px;
				.container{
					.wrapper{
						padding:  0 30px;
						.music-item{
							.split{
								gap: 20px;
								.side{
									text-align: center;
									img{
										width: 100%;
									}
									p{
										font-size: 45px;
									}
									span{
										font-size: 17px;
										text-transform: uppercase;
										margin: 20px 0;
										display: block;
									}
									&:first-child{
										a{
											display: block;
										}
									}
								}
							}
						}
					}
				}
				@include tablet-up{
					padding: 0;
					.container{
						.wrapper{
							.music-item{
								padding-bottom: 80px;
								.split{
									align-items: center;
									.side{
										img{
											box-shadow: 37px 27px 50px #00000096;
										}
										p{
											font-size: 90px;
											line-height: 1.2;
										}
										span{
											font-size: 38px;
											margin-top: 0;
										}
									}
								}
							}
						}
					}
				}
			}

		}
	}

	#videos{
		padding: 60px 0;
		background-color: $green-black;
		.container{
			text-align: center;
			.slider-videos{
				padding:  0 40px;
				margin-bottom: 60px;
			}
		}
	}

	#tour{
		.content{
			padding: 60px 0;
			.container{
				text-align: center;
				#events{
					margin-bottom: 60px;
					@include flex(column,center,center);
					gap: 30px;
					.event{
						@include flex(column,center,center);
						gap: 10px;
						.event-date{
							font-size: 16px;
						}
						.event-info{
							font-size: 16px;
							.event-location{
								margin-bottom: 10px;
							}
							.event-venue{
								font-size: 14px;
							}
						}
						&:nth-child(n + 6){
							display: none;
						}
					}
				}
			}
		}
		@include tablet-up{
			.content{
				padding: 100px 0 300px;
				.container{
					#events{
						margin-bottom: 130px;
						align-items: flex-end;
						.event{
							width: 100%;
							max-width: 900px;
							@include flex(row,space-between,center);
							.event-date{
								font-size: 24px;
							}
							.event-info{
								font-size: 24px;
								.event-location{
									font-size: 18px;
								}
							}
							.btn{
								padding: 22px 50px;
							}
						}
					}
				}
			}
		}
	}

	#about{
		.bg{
			object-position: center;
		}
		&::before{
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: #716F61;
			opacity: .75;
			z-index: 9;
		}
		.content{
			padding: 60px 0;
			z-index: 99;
			.container{
				text-align: left;
				.heading{
					text-align: left;
				}
				.bio{
					@include flex(column,initial,initial);
					gap: 30px;
					p{
						font-size: 16px;
						line-height: 1.4;
					}
				}
			}
		}
		@include tablet-up{
			background-color: #716F61;
			.bg{
				max-width: 50%;
			}
			&::before{
				width: 100px;
				color: red;
			}
			.content{
				padding: 100px 0;
				max-width: 50%;
				margin-left: auto;
				.container{
					.bio{
						max-width: 570px;
						p{
							font-size: 18px;
							line-height: 1.6;
						}
					}
				}
			}
		}
	}

	.bottom{
		background: transparent linear-gradient(180deg, #383013 0%, #906E41 100%) 0% 0% no-repeat padding-box;

		#shop{
			padding: 60px 0;
			.container{
				text-align: center;
				.wrapper{
					@include flex(column,center,center);
					gap: 40px;
					margin-bottom: 60px;
					a{
						display: block;
						img{
							display: block;
							max-width: 200px;
							margin: 0 auto 10px;
						}
					}
				}
			}
			@include tablet-up{
				padding: 100px 0;
				.container{
					.wrapper{
						@include flex(row,center,center);
						margin-bottom: 80px;
						a{
							flex: 1;
							img{
								max-width: 100%;
								margin-bottom: 30px;
							}
						}
					}
				}
			}
		}

		#partnerships{
			padding: 60px 0;
			.container{
				text-align: center;
				p{
					font-size: 18px;
					margin-bottom: 30px;
					display: inline-block;
				}
				.wrapper{
					max-width: 740px;
					margin: 0 auto;
					@include flex(column,center,center);
					gap: 30px;
					a{
						display: block;
						img{
							width: 100%;
							max-height: 200px;
							max-width: max-content;
							object-fit: contain;
							transition: all .4s;
						}
					}
				}
				.split{
					gap: 40px;
					.side{
						@include flex(column,center,center);
						gap: 20px;
						img{
							height: 200px;
							object-fit: contain;
							transition: all .4s;
							max-width: 400px;
							width: 100%;
						}
						iframe{
							width: 100%;
							max-width: 600px;
							height: 300px;
						}
					}
				}
			}
			@include tablet-up{
				padding: 100px 0;
				.container{
					p{
						font-size: 23px
					}
					.wrapper{
						@include flex(row,center,center);
						a{
							flex: 1;
							width: 50%;
							@include flex(column,center,center);
							@include hover-focus{
								img{
									transform: scale(1.05);
								}
							}
						}
					}
				}
			}
		}

		#instagram{
			padding: 60px 0;
			@include tablet-up{
				padding: 100px 0 160px;
			}
		}
	}

}

footer {
	.content{
		padding: 60px 0;
		.container{
			text-align: center;
			font-size: 16px;
			@include flex(column,center,center);
			gap: 30px;
			img{
				display: inline-block;
			}
			.wrapper{
				@include flex(column,center,center);
				gap: 10px;
				a{
					text-transform: uppercase;
					font-size: 24px;
					display: block;
				}
			}
			.copyright{
				p{
					font-size: 14px;
					text-transform: uppercase;
				}
			}
		}
	}
	@include tablet-up{
		.content{
			.container{
				.wrapper{
					@include flex(row,center,center);
					gap: 30px;
				}
			}
		}
	}
}

.popup{
	position: relative;
	width: 100%;
	max-width: 550px;
	margin:rem(30) auto;
	text-align: center;
	p{
		font-size: rem(40);
		text-transform: uppercase;
		text-align: center;
	}
}